<style src="./post-table.component.scss" lang="scss"></style>

<template>
 <div class="post-table">
   <div class="row">
     <div class="col-12">
       <table-component class="mt-30">
         <thead>
         <tr>
           <th >{{ locale.date }}</th>
           <th>{{ locale.time }}</th>
           <th>{{ locale.country }}</th>
           <th>{{ locale.status }}</th>
           <th>{{ locale.precinct }}</th>
         </tr>
         </thead>
         <tbody>
              <tr v-for="(item, index) in parcels" :key="index">
                <th>{{ item.date }}</th>
                <th>{{ item.time }}</th>
                <th>{{ item.country }}</th>
                <th>{{ item.status }}</th>
                <th>{{ item.precinct }}</th>
              </tr>
         </tbody>
       </table-component>
     </div>
   </div>
 </div>
</template>

<script src="./post-table.component.ts"></script>