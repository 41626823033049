<style src="./postal-tracking-service.component.scss" lang="scss"></style>

<template>
  <div class="postal-tracking-service">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
          <section class="mt-20 mb-100">
            <v-card class="h-container">
              <!--              <services-subtitle-component>-->
              <!--                {{ locale.header }}-->
              <!--              </services-subtitle-component>-->
              <div class="row">
                <div class="col-lg-6 col-12">
                  <form class="form" @submit.prevent="sendData">
                    <div class="row">
                      <div class="col-12">
                        <label for="number" class="v-label">{{ locale.trackingNumber }}</label>
                      </div>
                      <div class="col-12">
                        <div class="form__wrapper">
                          <input
                              placeholder="Izlənmə nömrəsini daxil edin"
                              v-model="trackingNumber"
                              type="text" class="v-input" id="number">
                          <v-divider class="mb-26 d-block d-lg-none"/>
                          <v-btn color="dark">{{ locale.search }}</v-btn>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <post-table-component class="d-none d-lg-block" :parcels="tracking" v-if="tracking.length"/>
              <not-data-component class="mt-20" v-if="!tracking.length && status.code">
                {{ status.text }}
              </not-data-component>
            </v-card>
            <post-mobile-component :parcels="tracking"/>

          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./postal-tracking-service.component.ts"></script>
