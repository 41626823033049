import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {PostalTrackingServiceSandbox} from '@/pages/postal-tracking-service/sandbox/postal-tracking-service.sandbox';
import {IServiceCommon} from '@/types/service-common';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import PostTableComponent from '@/pages/postal-tracking-service/components/post-table/post-table.component.vue'
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import {IStatus} from '@/types/status';
import {IParcel} from '@/pages/postal-tracking-service/types/parcel';
import {IServiceHeader} from '@/types/service-header';
import PostMobileComponent from '@/pages/postal-tracking-service/components/post-mobile/post-mobile.component.vue';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'postal-tracking-service-component',
    components: {
        ServiceNameComponent,
        NotDataComponent,
        PostTableComponent,
        ServiceSubtitleComponent,
        PostMobileComponent
    }
})

export default class PostalTrackingServiceComponent extends mixins(Router)  {
    public service = 1073;
    public sandbox = container.resolve(PostalTrackingServiceSandbox);

    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeMessage: any = null;
    public trackingNumber: string = '';


    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get status(): IStatus {
        return this.store.getters._getStatus;
    }

    get tracking(): IParcel[] {
        return this.store.getters._getTracking;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.postalTrackingService) {
                this.locale = res.postalTrackingService;
                this.localeMessage = res.message;
            }
        });
    }

    public mounted() {
        this.getServiceData();
    }

    public sendData() {
        if (this.trackingNumber.trim()) {
            this.sandbox.getPostTracking({
                trackingNumber: this.trackingNumber,
                token: localStorage.getItem('mainToken')
            });
        }
    }
}
