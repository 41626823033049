<style src="./post-mobile.component.scss" lang="scss"></style>

<template>
  <div class="post-mobile">
    <div class="row">
      <div class="col-12">
        <v-card class="d-block d-lg-none mt-20" v-if="parcels.length">
        <accordion-component>
          <accordion-item-component
              v-for="(item, index) in parcels"
              :key="index"
              :class="parcels.length===1?'wrapper--one':''"
          >
            <template v-slot:title>
              <div class="information">
                <div class="information__status">
                  {{ item.status }}
                </div>
              </div>
            </template>
            <template v-slot:content>
              <div class="information">
                <div class="information__title">
                  {{ locale.date }}
                </div>
                <div class="information__description">
                  {{ item.date }}
                </div>
              </div>
              <div class="information">
                <div class="information__title">
                  {{ locale.time }}
                </div>
                <div class="information__description">
                  {{ item.time }}
                </div>
              </div>
              <div class="information">
                <div class="information__title">
                  {{ locale.precinct }}
                </div>
                <div class="information__description">
                  {{ item.precinct }}
                </div>
              </div>
              <div class="information">
                <div class="information__title">
                  {{ locale.country }}
                </div>
                <div class="information__description">
                  {{ item.country }}
                </div>
              </div>
            </template>
          </accordion-item-component>
        </accordion-component>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script src="./post-mobile.component.ts"></script>
