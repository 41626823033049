import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import TableComponent from '@/core/shared/table/table.component.vue';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';

@Options({
    name: 'post-table-component',
    props: {
        parcels: {
            type: Array,
            required: true,
        }
    },
    components:{
        TableComponent,

    }
})

export default class PostTableComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public localeMessage: any = null;


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.postalTrackingService) {
                this.locale = res.postalTrackingService;
                this.localeMessage = res.message;
            }
        });
    }
}