import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class PostalTrackingServiceSandbox {
    private store = store;
    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }
    getPostTracking(payload: any): any {
        return this.store.dispatch('getPostTracking', payload);
    }
    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }
}